<template>
    <!-- 个人中心订单详情 -->
    <div class="type_area">
        <div class="faren_box" v-if="tableList.length>1">
            <div class="info_box">
                <el-checkbox v-model="summary.checked" @change="storeChecked($event)">多店订单编号:</el-checkbox>
                <span> <span style="color: #222;">{{$route.query.orderNumber||$route.query.mainOrderNumber||$route.query.prepareOrderNumber||$route.query.mainPrepareOrderNumber}}</span></span>
            </div>
            <div class="btn_box">
                <div class="info_box">
                    <div class="info_item" style="margin-right: 38px">共<span>{{summary.totalQuantity}}</span>件</div>
                    <div class="info_item" style="margin-right: 38px">优惠金额：<span>{{summary.preferPrice?'￥'+(summary.preferPrice).toFixed(2):'--'}}</span></div>
                    <div class="info_item" style="margin-right: 38px">应付金额：<span>{{summary.realPrice?'￥'+(summary.realPrice).toFixed(2):'--'}}</span></div>
                </div>
                <!-- <div class="btn_item" @click="exportOrder(orderNumber)">导出Excel</div> -->
                <!-- <div class="btn_item" v-if="(orderDetail.status=='待收货'||orderDetail.status=='已完成')" @click="invoiceClick(orderNumber)">查看发票</div> -->
                <div class="btn_item" @click="againBuyMultiple">再次购买</div>
                <!-- <div class="btn_item" v-if="(orderDetail.status=='待收货'||orderDetail.status=='已完成')" @click="deliveryClick(orderNumber)">查看物流</div> -->
                <!-- <div class="btn_item" v-if="(orderDetail.status=='待收货'||orderDetail.status=='已完成')" @click="outClick(orderNumber)">出库发货单</div> -->
                <!-- <div class="btn_item" v-if="orderDetail.status!='已完成'&&orderDetail.status=='待收货'" @click="suerClick(orderNumber)">确认收货</div>
                    <div class="btn_item" v-if="orderDetail.status=='待付款'" @click="cancelCilck(orderNumber)">取消订单</div> -->
                <!-- <el-popconfirm title="为了保证您的售后权益，请收到商品确认无误后再确定收货" @onConfirm="suerClick(orderNumber)" v-if="orderDetail.status!='已完成'&&orderDetail.status=='待收货'">
                        <div class="btn_item" slot="reference" v-if="orderDetail.status!='已完成'&&orderDetail.status=='待收货'">确认收货</div>

                    </el-popconfirm> -->
                <el-popconfirm v-if="$route.query.from!='wait'&&!$route.query.prepareOrderNumber" title="取消后无法恢复，优惠券可退回，有效期内使用" @onConfirm="cancelCilckMultiple">
                    <div v-if="$route.query.from!='wait'&&!$route.query.prepareOrderNumber" class="btn_item" slot="reference">取消订单</div>

                </el-popconfirm>
                <div v-if="$route.query.from!='wait'&&!$route.query.prepareOrderNumber" class="btn_item active" @click="goMultiplePay($route.query.orderNumber)">去支付</div>
            </div>
        </div>
        <div class="order_detail" v-for="(item,index) in tableList" :key="index">
            <!-- 订单编号 -->
            <div class="order_info">
                <el-checkbox v-model="item.checked" @change="singleChecked(item,$event)">{{item.orderInfo.customerName  }}</el-checkbox>
                <div class="order_number">
                    <img style="margin-right: 4px;" src="@/assets/images/order/ddbh.svg" alt="">
                    <span>订单编号：{{item.orderInfo.orderNumber}}</span>
                    <div class="date">下单时间：<span>{{item.orderInfo.postDate}}</span></div>
                </div>
                <span class="status" v-if="$route.query.from=='wait'">待审核</span>
                <span class="status" v-if="$route.query.from=='now'">{{item.orderInfo.status}}</span>
            </div>
            <!-- 进度步骤 -->
            <div class="process_box" v-if="item.orderSteps&&item.orderSteps.length == 4">
                <div class="process_item" v-for="(el,indexs) in item.orderSteps" :key="indexs">
                    <span class="item_title" :class="{active: el.isActive,hui:el.state}">
                        <span v-if="indexs == 0">①</span>
                        <span v-if="indexs == 1">②</span>
                        <span v-if="indexs == 2">③</span>
                        <span v-if="indexs == 3">④</span>
                        {{el.name}}</span>
                    <span style="height: 16px;">{{el.datetime}}</span>
                </div>
            </div>
            <div class="process_box kh" v-if="item.orderSteps&&item.orderSteps.length == 5">
                <div class="process_item" v-for="(el,indexs) in item.orderSteps" :key="indexs">
                    <span class="item_title" :class="{active: el.isActive,hui:el.state}">
                        <span v-if="indexs == 0">①</span>
                        <span v-if="indexs == 1">②</span>
                        <span v-if="indexs == 2">③</span>
                        <span v-if="indexs == 3">④</span>
                        <span v-if="indexs == 4">⑤</span>
                        {{el.name}}</span>
                    <span style="height: 16px;">{{el.datetime}}</span>
                </div>
            </div>
            <!-- 收货信息 -->
            <div class="receive_info">
                <div class="box_title">收货信息</div>
                <div class="info_box">
                    <div class="info_item">
                        <div class="info_title">{{item.orderInfo.contacter}} <span style="color: #CFCFCF;font-size: 12px;font-weight: 400;margin: -3px 6px 0px;">|</span> {{item.orderInfo.mobile}}</div>
                        <div>{{item.orderInfo.address}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_title">配送信息</div>
                        <span v-if="!item.orderInfo.waybill">--</span>
                        <div class="delivery_item" v-else>
                            <span>{{item.orderInfo.waybill.expressCompany||'--'}} </span>
                            <!-- <span v-if="orderDetail.waybill.length>1" class="more" @click="dialogVisible = true">更多配送信息<i class="el-icon-arrow-right icon"></i></span> -->
                            <span>运单号：{{item.orderInfo.waybill.waybillNumber||'--'}}</span>
                            <span>送达时间：{{item.orderInfo.waybill.deliveryTime||'--'}}</span>
                        </div>
                    </div>
                    <div class="info_item">
                        <div class="info_title">付款信息</div>
                        <span>实付金额：{{item.orderInfo.paid?'￥'+(item.orderInfo.paid).toFixed(2):'--'}}</span>
                        <span>支付时间：{{item.orderInfo.paymentDate||'--'}}</span>
                        <span>支付方式：{{item.orderInfo.paymentMethod||'--'}}<span class="pay_img" v-if="item.orderInfo.proofUrl" @click="payShow(item.orderInfo.proofUrl)">支付凭证</span></span>
                        <span>发票类型：{{item.orderInfo.invoiceName||'--'}}</span>
                        <span>订单备注：{{item.orderInfo.note||'--'}}</span>
                    </div>
                </div>
            </div>
            <!-- 商品列表-列表模式 -->
            <div class="list_mode">
                <div class="box_title">商品清单</div>
                <div class="goods_list">
                    <div class="list_title">
                        <span>商品信息</span>
                        <span>单价</span>
                        <span>数量</span>
                        <span>金额</span>
                        <span>操作</span>
                    </div>
                    <div class="list_item" v-for="(ele) in item.cartInfo.cartGoodsInfo" :key="ele.goodsId">
                        <div class="item_left">
                            <!-- 商品图片 -->
                            <div class="goods_img">
                                <img :src="ele.goodsSingleInfo.thumbUrl" alt="">
                            </div>
                            <!-- 商品信息 -->
                            <div class="goods_info">
                                <div class="title">
                                    <div class="tag" v-if="ele.cart.actType == 2||ele.cart.actType == 4||ele.cart.actType == 5">
                                        <span :class="{tj:ele.cart.actType == 2,yh:ele.cart.actType == 4||ele.cart.actType == 5}">{{ele.cart.actType==2?'特价':(ele.cart.actType==4?'满赠':'赠品')}}</span>
                                    </div>
                                    <span>{{ele.goodsSingleInfo.productName}}</span>
                                </div>
                                <div class="info_box">
                                    <div class="info_item">
                                        <div>
                                            <span class="info_name">厂商</span>
                                            <span>{{ele.goodsSingleInfo.produceUnit||'--'}}</span>
                                        </div>
                                    </div>
                                    <div class="info_item">
                                        <div>
                                            <span class="info_name">效期</span>
                                            <span>{{ele.goodsSingleInfo.lastDate||'--'}}</span>
                                        </div>
                                    </div>
                                    <div class="info_item">
                                        <div>
                                            <span class="info_name">规格</span>
                                            <el-tooltip class="item" effect="dark" :disabled="ele.goodsSingleInfo.norms&&ele.goodsSingleInfo.norms.length<30" :content="ele.goodsSingleInfo.norms" placement="top-start">
                                                <span class="spesc">{{ele.goodsSingleInfo.norms||'--'}}</span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <!-- <div class="info_item">
                                        <div>
                                            <span class="info_name">库存</span>
                                            <span>{{item.goodsSingleInfo.stock}}</span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="item_right price" v-html="initPrice(ele.goodsSingleInfo.jiage)"></div>
                        <div class="item_right number">{{ele.cart.quantity}}</div>
                        <div class="item_right price font_weight_bold" v-html="initPrice(ele.goodsSingleInfo.jiage*ele.cart.quantity)"></div>
                        <div class="item_right action" v-if="ele.downTitle=='下载首营资料'" @click="downClick(ele.cart,item)"> 下载首营资料</div>
                        <div class="item_right action" v-if="ele.downTitle=='下载中'"> 下载中</div>
                    </div>
                </div>
            </div>
            <div class="bottom_info">
                <div class="info_box">
                    <div class="info_item" style="">共<span>{{item.cartInfo.kindNum}}</span>件</div>
                    <div class="info_item">优惠券总额：<span>{{item.orderCouponInfos&&item.orderCouponInfos.preferPrice?'￥'+(item.orderCouponInfos.preferPrice).toFixed(2):'--'}}</span></div>
                    <div class="info_item">优惠金额：<span>{{item.cartInfo.preferPrice?'￥'+(item.cartInfo.preferPrice).toFixed(2):'--'}}</span></div>
                    <div class="info_item shifu">
                        <div>实付金额：<span>{{item.cartInfo.realPrice?'￥'+(item.cartInfo.realPrice).toFixed(2):'--'}}</span></div>
                        <!-- <span class="desc">（运费金额：￥{{ele.extra}}元，满500免运费）</span> -->
                    </div>
                </div>
                <div class="btn_box">
                    <!-- <div class="btn_item" @click="exportOrder(orderDetail.orderNumber)">导出Excel</div>
          <div class="btn_item" v-if="$route.query.from=='now'">查看发票</div>
          <div class="btn_item" @click="againBuy(orderDetail.orderNumber)">再次购买</div>
          <div class="btn_item" v-if="$route.query.from=='now'">查看物流</div>
          <div class="btn_item" v-if="$route.query.from=='now'">确认收货</div>
          <div class="btn_item" v-if="$route.query.from=='now'">取消订单</div>
          <div class="btn_item active" v-if="$route.query.from=='now'">去支付</div> -->
                    <div class="btn_item" @click="exportOrder(item.orderInfo.orderNumber)">导出Excel</div>
                    <div class="btn_item" v-if="(item.orderInfo.status=='待收货'||item.orderInfo.status=='已完成')" @click="invoiceClick(item.orderInfo.orderNumber)">查看发票</div>
                    <div class="btn_item" @click="againBuy(item.orderInfo.orderNumber)">再次购买</div>
                    <div class="btn_item" v-if="(item.orderInfo.status=='待收货'||item.orderInfo.status=='已完成')" @click="deliveryClick(item.orderInfo.orderNumber)">查看物流</div>
                    <div class="btn_item" v-if="(item.orderInfo.status=='待收货'||item.orderInfo.status=='已完成')" @click="outClick(item.orderInfo.orderNumber)">出库发货单</div>
                    <!-- <div class="btn_item" v-if="item.orderInfo.status!='已完成'&&item.orderInfo.status=='待收货'" @click="suerClick(item.orderInfo.orderNumber)">确认收货</div>
                    <div class="btn_item" v-if="item.orderInfo.status=='待付款'" @click="cancelCilck(item.orderInfo.orderNumber)">取消订单</div> -->
                    <el-popconfirm title="为了保证您的售后权益，请收到商品确认无误后再确定收货" @onConfirm="suerClick(item.orderInfo.orderNumber)" v-if="item.orderInfo.status!='已完成'&&item.orderInfo.status=='待收货'">
                        <div class="btn_item" slot="reference" v-if="item.orderInfo.status!='已完成'&&item.orderInfo.status=='待收货'">确认收货</div>

                    </el-popconfirm>

                    <el-popconfirm title="取消后无法恢复，优惠券可退回，有效期内使用" @onConfirm="cancelCilckMultiple(item.orderInfo.orderNumber)" v-if="item.orderInfo.status=='待付款'">
                        <div class="btn_item" v-if="item.orderInfo.status=='待付款'" slot="reference">取消订单</div>

                    </el-popconfirm>
                    <div class="btn_item active" v-if="item.orderInfo.status=='待付款'" @click="goPay(item.orderInfo.orderNumber)">去支付</div>
                </div>
            </div>
        </div>
        <!-- 微信截图预览 -->
        <el-dialog class="img_pre" :visible.sync="imgDialog" width="800px">
            <div class="img_box" style="text-align: center;">
                <img style="max-width: 100%;max-height: 69vh;" :src="payImg" alt="" />
            </div>
        </el-dialog>
        <!-- 物流信息弹窗 -->
        <el-dialog class="dialog_box" title="查看物流" :visible.sync="logisticDialog" width="470px" center>
            <div class="dialog_main">
                <div v-if="deliveryList.length">
                    <div class="delivery_item" :class="{active: deliveryList.length>1}" v-for="(item,index) in deliveryList" :key="index">
                        <div class="item_show" v-if="!item.showDetail&&deliveryList.length>1">
                            <span>{{item.expressCompany}}：{{item.waybillNumber}}</span>
                            <span @click="deliveryDetail(item)">查看详情</span>
                        </div>
                        <div class="item_detail" :class="{active: deliveryList.length>1}" v-else>
                            <div class="title">{{item.expressCompany}}：{{item.waybillNumber}}</div>
                            <div class="node_box">
                                <el-timeline>
                                    <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.type" :color="activity.color" :size="activity.size" :timestamp="activity.acceptTime">
                                        {{activity.acceptStation}}
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                            <div class="btn" @click="item.showDetail = false" v-if="deliveryList.length>1">收起<i class="el-icon-arrow-up icon"></i></div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    暂无物流信息
                </div>
            </div>
        </el-dialog>
        <!-- 发票弹窗 -->
        <el-dialog class="dialog_box" title="查看发票" :visible.sync="invoiceDialog" width="470px" center>
            <div class="dialog_main">
                <div class="delivery_item" :class="{active: invoiceList.length>1}" v-for="(item,index) in invoiceList" :key="index">
                    <div class="item_show">
                        <span>{{item.deliveryOrderNumber}}</span>
                        <span @click="invoiceDetail(item.invoiceUrl)">查看详情</span>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 出库弹窗 -->
        <el-dialog class="dialog_box" title="出库发货单" :visible.sync="outOrderDialog" width="470px" center>
            <div class="dialog_main">
                <div class="delivery_item" :class="{active: outOrderList.length>1}" v-for="(item,index) in outOrderList" :key="index">
                    <div class="item_show">
                        <span>{{item.deliveryOrderNumber}}</span>
                        <span @click="outOrderDetail(item.deliveryOrderNumber)">查看详情</span>
                    </div>
                </div>
            </div>
        </el-dialog>
        <priceDialog v-if="tipVisible" @tipClose="tipClose" @closePriceDialog="noBuy" :priceList="tipList" :tipVisible="tipVisible"></priceDialog>
    </div>
</template>
<script>
import { downloadFile } from '@/utils/common'
import { getCarNum } from '@/utils/carNumber'
import priceDialog from '@/views/shopCar/component/priceDialog.vue'
export default {
    name: 'OrderPersDetail',
    components: {
        priceDialog
    },
    data() {
        return {
            imgDialog: false,
            payImg: '',
            dialogVisible: false,
            outOrderDialog: false,
            price: 369.36,
            orderNumber: '',
            orderDetail: {},
            logisticList: [],
            allDetail: {},
            cartInfo: {},
            summary: {},
            statusList: [],
            tableList: [],
            orderSteps: [],
            deliveryList: [],
            outOrderList: [],
            invoiceList: [],
            activities: [],
            logisticDialog: false,
            invoiceDialog: false,
            tipList: [],//
            tipVisible: false,
        };
    },
    created() {
        console.log(this.$route.query.from);
    },
    mounted() {

        this.orderNumber = this.$route.query.orderNumber || this.$route.query.prepareOrderNumber || ''
        this.getOrderDetail()
        // this.getLogisticList()
    },
    computed: {
        goodsCount() {
            return this.cartInfo.cartGoodsInfo ? this.cartInfo.cartGoodsInfo.reduce((pre, item) => {
                return pre + item.cart.quantity
            }, 0) : 0
        }
    },
    methods: {
        storeChecked(val) {
            console.log(val);

            this.tableList.forEach(item => {
                item.checked = val
            })
            console.log(this.tableList);
        },
        tipClose() {
            this.tipVisible = false
        },
        noBuy() {
            this.tipVisible = false
        },
        singleChecked(item, val) {
            console.log(item);

            this.summary.checked = this.tableList.some(item => {
                return item.checked
            })




        },
        // 获取订单详情
        getOrderDetail() {
            this.$api.multipleOrderDetail({ orderNumber: this.orderNumber ? this.orderNumber.split(',') : [], mainOrderNumber: this.$route.query.mainOrderNumber || this.$route.query.mainOrderNumber }).then((res) => {
                if (res.data.code === 200) {
                    res.data.data.list.forEach(item => {
                        item.checked = false
                        item.orderSteps.forEach((el, index) => {
                            if (index == item.orderSteps.length - 1 && el.state) {
                                el.isActive = true
                            }
                            if (index + 1 <= item.orderSteps.length && el.state && !item.orderSteps[index + 1].state) {
                                el.isActive = true
                            }
                        })
                        const newarr = []
                        item.cartInfo.cartGoodsInfo.forEach(ele => {
                            ele.downTitle = '下载首营资料'
                            newarr.push(ele)
                            if (ele.giftsSendInfo.length) {
                                ele.giftsSendInfo.forEach(el => {
                                    el.downTitle = '下载首营资料'
                                    newarr.push(el)
                                })
                            }
                        })
                        if(item.cartInfo.promotionGiftsSendInfo.length){
                            item.cartInfo.promotionGiftsSendInfo.forEach(ele=>{
                                ele.downTitle = '下载首营资料'
                                newarr.push(ele)
                            })
                        }
                        item.cartInfo.cartGoodsInfo = newarr
                        console.log(item.cartInfo.cartGoodsInfo, 'item.cartInfo.cartGoodsInfo');
                        // if(item.cartInfo.promotionGiftsSendInfo.length){
                        //     item.cartInfo.promotionGiftsSendInfo.forEach(ele=>{
                        //           ele.downTitle = '下载首营资料'
                        //         item.cartInfo.cartGoodsInfo.push(ele)
                        //     })
                        // }
                    })
                    this.tableList = res.data.data.list
                    res.data.data.summary.checked = false
                    this.summary = res.data.data.summary
                    // this.orderDetail = res.data.data.orderInfo
                    // this.allDetail = res.data.data
                    // this.cartInfo = res.data.data.cartInfo
                    // const newarr = []
                    // this.cartInfo.cartGoodsInfo.forEach(item => {
                    //     item.downTitle = '下载首营资料'
                    //     newarr.push(item)
                    //     if(item.giftsSendInfo.length){
                    //          item.giftsSendInfo.forEach(el=>{
                    //             el.downTitle = '下载首营资料'
                    //             newarr.push(el)
                    //          })
                    //     }
                    // })
                    // this.cartInfo.cartGoodsInfo=newarr
                    //           console.log(this.cartInfo.cartGoodsInfo, 'this.cartInfo.cartGoodsInfo');
                    // if(this.cartInfo.promotionGiftsSendInfo.length){
                    //     this.cartInfo.promotionGiftsSendInfo.forEach(item=>{
                    //           item.downTitle = '下载首营资料'
                    //         this.cartInfo.cartGoodsInfo.push(item)
                    //     })
                    // }

                    // this.orderSteps = res.data.data.orderSteps
                    // this.orderSteps.forEach((item, index) => {
                    //     if (index == this.orderSteps.length - 1 && item.state) {
                    //         item.isActive = true
                    //     }
                    //     if (index + 1 <= this.orderSteps.length && item.state && !this.orderSteps[index + 1].state) {
                    //         item.isActive = true
                    //     }
                    // })
                }
            });
        },
        async getLogisticList() {
            const res = await this.$api.getDeliveryList({ orderNumber: this.orderNumber })
            if (res.data.code === 200) {
                this.logisticList = res.data.data[0]
            }
        },
        goPay(orderNumber) {
            this.$router.push(`/pay?orderNumber=${orderNumber}&mainOrderNumber=${this.$route.query.mainOrderNumber ? this.$route.query.mainOrderNumber : ''}`)
        },
        async goMultiplePay() {
            if (!this.summary.checked) return this.$message.warning('请选择要购买的订单')
            const ordersList = this.tableList.filter(item => item.checked)
            const orderNumber = ordersList.map(item => item.orderInfo.orderNumber)
            const res = await this.$api.multipleCanPay({ mainOrderNumber: this.$route.query.mainOrderNumber, orderNumber })
            if (!res.data.data.length) {
                this.$router.push(`/pay?mainOrderNumber=${this.$route.query.mainOrderNumber}&orderNumber=${orderNumber}`)
            } else {
                this.$message.warning(res.data.data.join(','))

            }
            // this.$router.push(`/pay?orderNumber=${orderNumber}`)
        },
        // 下载首营资料
        async downClick(item, el) {
            el.downTitle = '下载中'
            console.log(el);
            this.$forceUpdate()
            const res = await this.$orderApis.downloadLicense({ goodsId: item.goodsId })
            //    if(res.data.code==100001) return this.$message.error(res.msg)
            if (res.status == 200) {
                downloadFile(res)
                el.downTitle = '下载首营资料'
                this.$forceUpdate()
            } else {
                el.downTitle = '下载首营资料'
                this.$forceUpdate()
            }

        },
        async suerClick(orderNumber) {
            const res = await this.$api.confirmReceipt({ orderNumber })
            if (res.status == 200) {
                this.$message.success('确认收货成功')
                this.getOrderDetail()
            }
        },
        async cancelCilckMultiple() {
            if (!this.summary.checked) return this.$message.warning('请选择要取消的订单')
            const ordersList = this.tableList.filter(item => item.checked)
            const orderNumber = ordersList.map(item => item.orderInfo.orderNumber)
            const res = await this.$api.multipleOrderCancel({ mainOrderNumber: this.$route.query.orderNumber, orderNumber })
            if (res.data.code == 200) {
                this.$message.success('取消订单成功')
                this.getOrderDetail()
            }
        },
        exportOrder(orderNumber) {
            this.$api.orderExport({ orderNumber }).then((res) => {
                // if (res.status == 200) {
                //     // this.isLoading=false

                // }
            });
        },
        async againBuy(orderNumber) {
            const res = await this.$api.buyAgain({ orderNumber })
            if (res.data.code == 200) {
                if (res.data.data.needCheck) {
                    this.tipList = res.data.data.needSureGoods
                    this.tipVisible = true
                }
                this.$message.success('已将订单商品加入购物车')
                getCarNum(this)
            }
        },
        async againBuyMultiple(item) {
            console.log(item);
            if (!this.summary.checked) return this.$message.warning('请选择要购买的订单')
            const ordersList = this.tableList.filter(item => item.checked)
            const orderNumber = ordersList.map(item => item.orderInfo.orderNumber)
            const res = await this.$api.multipleOrderAgain({ mainOrderNumber: this.$route.query.orderNumber, orderNumber })
            // if (res.data.code == 200) {
            //     this.$message.success('取消订单成功')
            //      this.getMultipleList()
            // }
            if (res.data.code == 200) {
                if (res.data.data.notice && res.data.data.notice.length) {
                    this.$notify({
                        title: '提示',
                        message: res.data.data.notice.join('\n') + '，其余加入购物车成功',
                        type: 'warning'
                    });
                } else {
                    this.$message.success('已将订单商品加入购物车')
                }

                getCarNum(this)
            }
        },
        // 查看物流按钮
        async deliveryClick(orderNumber) {
            const res = await this.$api.getDeliveryList({ orderNumber })
            if (res.data.code === 200) {
                this.deliveryList = res.data.data
                this.deliveryList.forEach(i => {
                    i.showDetail = false
                })
                if (res.data.data.length === 1) {
                    const fd = new FormData()
                    fd.append('id', res.data.data[0].id)
                    const result = await this.$api.getDeliveryDetailList(
                        fd
                    )
                    if (result.data.code == 200) {
                        if (result.data.data.length) {
                            this.activities = result.data.data
                            this.activities[0].color = '#337CFF'
                        }
                    }
                }
                this.logisticDialog = true
            }
        },
        // 查看发票按钮
        async invoiceClick(orderNumber) {
            const res = await this.$api.getInvoiceList({ orderNumber })
            if (res.data.code === 200) {
                this.invoiceList = res.data.data
                this.invoiceDialog = true
            }


        },
        // 物流弹窗查看详情
        async deliveryDetail(item) {
            // const result = await this.$api.getDeliveryDetailList({ id: item.id })
            const fd = new FormData()
            fd.append('id', item.id)
            const result = await this.$api.getDeliveryDetailList(
                fd
            )
            if (result.data.code == 200) {
                this.activities = result.data.data
                this.activities[0].color = '#337CFF'
            }
            this.deliveryList.forEach(i => {
                i.showDetail = false
            })
            item.showDetail = true
        },
        invoiceDetail(item) {
            window.location.href = item
        },
        outOrderDetail(orderNumber) {
            this.$api.downloadOutOrder({ orderNumber }).then((res) => {
                // if (res.status == 200) {
                //     // this.isLoading=false

                // }
            });
            // window.location.href = item
        },
        // 查看出库按钮
        async outClick(orderNumber) {
            const res = await this.$api.outOrder({ orderNumber })
            if (res.data.code === 200) {
                this.outOrderList = res.data.data
                this.outOrderDialog = true
            }


        },
        payShow(item) {
            this.imgDialog = true
            this.payImg = item
        }
    }
};
</script>
<style lang="scss" scoped>
.faren_box {
    margin-top: 14px;
    background: #fff;
    padding: 24px;
    .info_box {
        display: flex;
        align-items: center;
        // justify-content: flex-end;

        .info_item {
            color: #444141;
            font-size: 16px;
            // margin-left: 20px;

            span {
                font-weight: 600;
                color: #f92b2b;
            }

            &.shifu {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 22px;
                }

                .desc {
                    font-weight: 400;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
    }

    .btn_box {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        margin-top: 16px;

        .btn_item {
            width: 86px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            border-radius: 100px 100px 100px 100px;
            border: 1px solid #cccccc;
            font-size: 16px;
            color: #111111;
            cursor: pointer;

            &.active {
                border-color: #0687fe;
                color: #0687fe;

                &:hover {
                    border-color: #337cff;
                    color: #337cff;
                }
            }

            &:hover {
                border-color: #e3e3e3;
                color: #666666;
            }
        }
    }
}
.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px;
        }
        .el-dialog__header,
        .el-dialog__title {
            text-align: left;
            font-size: 20px;
            color: #1a1a1a;
            font-weight: 600;
        }
        .el-dialog__headerbtn {
            top: 24px;
        }
        .el-dialog--center .el-dialog__body {
            padding-top: 6px;
        }
    }
    .dialog_main {
        max-height: 500px;
        overflow-y: auto;
        padding-left: 20px;
        .delivery_item {
            font-weight: bold;
            font-size: 14px;
            color: #333333;

            &.active {
                background: #fafcfd;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #e6ebf5;
                margin-bottom: 9px;
            }

            .item_show {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 12px;

                span:last-of-type {
                    font-weight: 400;
                    font-size: 12px;
                    color: #337cff;
                    cursor: pointer;

                    &:hover {
                        color: #0687fe;
                    }
                }
            }

            .item_detail {
                &.active {
                    padding: 16px 20px;
                }

                .title {
                    font-size: 18px;
                    padding-bottom: 13px;
                    margin-bottom: 14px;
                    border-bottom: 1px solid #e6ebf5;
                }

                .btn {
                    font-size: 18px;
                    color: #337cff;
                    cursor: pointer;
                    text-align: center;

                    .icon {
                        font-weight: 600;
                        margin-left: 6px;
                    }

                    &:hover {
                        color: #0687fe;
                    }
                }
            }
        }
    }
}
.dialog_box {
    ::v-deep {
        .el-dialog {
            border-radius: 10px;
        }
        .el-dialog__header {
            text-align: left;
            font-size: 20px;
            color: #1a1a1a;
            font-weight: 600;
        }
        .el-dialog__headerbtn {
            top: 24px;
        }
        .el-dialog--center .el-dialog__body {
            padding-top: 6px;
        }
    }
    .dialog_main {
        .delivery_item {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            color: #666666;
            margin-top: 7px;
            line-height: 22px;

            span:first-of-type {
                font-weight: bold;
                color: #333333;
            }
        }
    }
}
.order_detail {
    padding: 20px 24px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    margin-top: 16px;
    overflow: hidden;

    .top_number {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .order_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 12px;

        .order_number {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            color: #333333;

            .date {
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                margin-left: 22px;

                span {
                    color: #222222;
                }
            }
        }

        .status {
            font-weight: bold;
            font-size: 18px;
            color: #f7a400;
        }
    }

    .box_title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            width: 6px;
            height: 16px;
            background: #1a9dff;
            border-radius: 100px 100px 100px 100px;
            margin-right: 7px;
        }
    }

    .receive_info {
        margin-bottom: 12px;

        .info_box {
            padding: 14px 16px;
            background: #f7f7f7;
            border-radius: 2px 2px 2px 2px;
            display: flex;
            align-items: flex-start;
            font-size: 12px;
            color: #666666;

            .info_item {
                width: 280px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 80px;

                .delivery_item {
                    display: flex;
                    flex-direction: column;

                    .more {
                        color: #1a9dff;
                        cursor: pointer;
                        margin-left: 10px;
                        &:hover {
                            color: #0687fe;
                        }
                        .icon {
                            font-weight: 600;
                        }
                    }
                }

                .pay_img {
                    margin-left: 16px;
                    color: #1a9dff;
                    cursor: pointer;
                    &:hover {
                        color: #0687fe;
                    }
                }

                .info_title {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 11px;
                }

                span {
                    margin-bottom: 6px;
                }
            }
        }
    }

    .list_mode {
        .goods_list {
            border: 1px solid #eeeeee;

            .list_title {
                display: flex;
                align-items: center;
                height: 40px;
                border-bottom: 1px solid #eeeeee;
                font-size: 14px;

                span {
                    flex: 1;
                    text-align: center;

                    &:first-of-type {
                        flex: 3;
                        text-align: left;
                        text-indent: 71px;
                        margin-left: 16px;
                    }
                }
            }

            .list_item {
                position: relative;
                padding: 13px 0;
                border-bottom: 1px solid #eeeeee;
                background: #fff;
                display: flex;
                align-items: center;

                &:hover {
                    background: #f5fbff;
                }

                .goods_img {
                    margin-right: 18px;
                    img {
                        width: 130px;
                        height: 130px;
                    }
                }

                .goods_info {
                    .title {
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 13px;
                        .tag {
                            display: flex;
                            align-items: center;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30px;
                                height: 17px;
                                box-sizing: border-box;
                                border-radius: 2px;
                                font-size: 10px;
                                line-height: 17px;
                                margin-right: 4px;
                            }

                            .tj {
                                background: rgba(248, 213, 213, 0.9);
                                border: 1px solid #ffacac;
                                color: #f92b2b;
                            }
                            .zy {
                                background: rgba(206, 231, 249, 0.9);
                                border: 1px solid #b7dcf8;
                                color: #1a9dff;
                            }
                            .yh {
                                width: auto;
                                padding: 0 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                color: #f7a400;
                            }
                        }
                    }
                    .info_box {
                        .info_item {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 5px;

                            & > div {
                                width: 320px;
                                margin-right: 16px;
                                display: flex;
                                align-items: center;
                            }

                            .info_name {
                                color: #999999;
                                margin-right: 12px;
                            }
                        }
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        margin-top: 7px;

                        .price_box {
                            font-size: 20px;
                            color: #f34141;
                            font-weight: 600;
                        }

                        .yh_box {
                            position: relative;
                            margin-left: 10px;
                            padding-bottom: 2px;
                            .yh {
                                width: auto;
                                padding: 2px 6px;
                                background: rgba(248, 240, 223, 0.9);
                                border: 1px solid #fad894;
                                border-radius: 4px;
                                font-size: 12px;
                                color: #f7a400;
                            }
                            .sj {
                                position: absolute;
                                left: -4px;
                                top: 8px;
                            }
                        }
                    }
                }

                .item_left {
                    flex: 3;
                    display: flex;
                    margin-left: 16px;
                }

                .item_right {
                    flex: 1;
                    text-align: center;
                    font-size: 18px;

                    &.price {
                        color: #f92b2b;
                    }
                    &.action {
                        font-size: 14px;
                        color: #1a9dff;
                        cursor: pointer;

                        &:hover {
                            color: #0687fe;
                        }
                    }
                }
            }
        }
    }

    .bottom_info {
        margin-top: 14px;
        .info_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .info_item {
                color: #444141;
                font-size: 16px;
                margin-left: 20px;

                span {
                    font-weight: 600;
                    color: #f92b2b;
                }

                &.shifu {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 22px;
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

        .btn_box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 16px;

            .btn_item {
                width: 86px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                border-radius: 100px 100px 100px 100px;
                border: 1px solid #cccccc;
                font-size: 16px;
                color: #111111;
                cursor: pointer;

                &.active {
                    border-color: #0687fe;
                    color: #0687fe;

                    &:hover {
                        border-color: #337cff;
                        color: #337cff;
                    }
                }

                &:hover {
                    border-color: #e3e3e3;
                    color: #666666;
                }
            }
        }
    }
}
.spesc {
    display: inline-block;
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.process_box {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    &.kh {
        .process_item {
            width: 238px;

            .item_title {
                background: url('~@/assets/images/order/bzs.png') no-repeat;
                background-size: 100% 100%;

                &.hui {
                    background: url('~@/assets/images/order/bzs_a1.png')
                        no-repeat;
                    background-size: 100% 100%;
                }
                &.active {
                    background: url('~@/assets/images/order/bzs_a.png')
                        no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .process_item {
        width: 294px;
        flex-shrink: 0;
        margin-left: -10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #666666;

        &:first-of-type {
            margin-left: 0;
        }

        .item_title {
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: url('~@/assets/images/order/bz.png');
            background-size: 100%;
            margin: 16px 0 6px;
            font-size: 16px;
            color: #333333;
        }
        .item_title.hui {
            color: #fff;
            background: url('~@/assets/images/order/bz_a1.png');
        }
        .item_title.active {
            color: #fff;
            background: url('~@/assets/images/order/bz_a.png');
        }
    }
}
</style>